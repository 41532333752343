import { AlertType } from "./const/alert";

export const HIDDEN_CLASS = `visuallyhidden`;
export const UNDEFINED_UNIX_DATE = 1893438000; // '2030-01-01T00:00:00+00:00'
export const CONTENT_FILE_FOR_REGISTRATION_CLASS_NAME = `form-registration__input form-registration__input--file`;
export const FORMAT_TEXT = `text/plain`;

export const ScreenWidth = {
  MOBILE: 680
};

export const DOMAIN = {
  TT: `hgfdsa`,
  ZZ: `https://zz.hgfdsa.ru/`,
  PT: `primetime`,
  PT_RES: `rprimetime`,
};

export const PageName = {
  REGISTRATION: `registration`,
  CONTEST_ADM: `contest_adm`,
  ORDER_TURN: `order_turn`,
  RESULTS: `results`,
  REFEREE: `referee`,
  CONTEST_SETTINGS: `contest_settings`,
  BACKDROP: `backdrop`,
  SOUNDMAN: `soundman`,
  SCOREBOARD: `scoreboard`,
  AUTH_DOCUMENT: `auth_document`,
  PAYMENT: `payment`,
  SCANNER: `scanner`,
  SHOW_DOCUMENT: `show_document`,
  SEMAPHORE: `semaphore`,
  SCHEDULE: `schedule`,
  TITER: `titer`,
  TITER_SETTINGS: `titer_settings`,
  // ADMIN: `admin`,
  MONITOR: `monitor`,
  CALENDAR: `calendar`,
  SCENA: `scena`,
  COMPERE: `compere`,
  TERMINAL: `terminal`,
};

export const PAGE_ID = {
  [PageName.BACKDROP]: `2`,

  [PageName.CONTEST_ADM]: `4`,
  [PageName.CONTEST_SETTINGS]: `5`,
  [PageName.ORDER_TURN]: `6`,
  [PageName.REFEREE]: `7`,
  [PageName.REGISTRATION]: `8`,
  [PageName.RESULTS]: `9`,
  [PageName.SCOREBOARD]: `10`,
  [PageName.SHOW_DOCUMENT]: `11`,
  [PageName.SOUNDMAN]: `12`,

  [PageName.SCANNER]: `14`,
  [PageName.SEMAPHORE]: `15`,
  [PageName.TITER]: `16`,
  [PageName.MONITOR]: `17`,
  [PageName.TITER_SETTINGS]: `18`,

  [PageName.PAYMENT]: `20`,
  [PageName.CALENDAR]: `21`,

  [PageName.SCENA]: `24`,
  [PageName.COMPERE]: `3`,

  // [PageName.AUTH_DOCUMENT]: `Проверка документов`,
};

export const ORDER_ACTION_PAGE = [
  PageName.REGISTRATION,
  PageName.CONTEST_ADM,
  PageName.ORDER_TURN,
  PageName.RESULTS,
  PageName.REFEREE,
  PageName.CONTEST_SETTINGS,
  // PageName.BACKDROP,
  PageName.SOUNDMAN,
  PageName.COMPERE,
  PageName.SCOREBOARD,
  PageName.AUTH_DOCUMENT,
  PageName.SCANNER,
  PageName.SHOW_DOCUMENT,
  PageName.MONITOR,
];

export const ACTION_NAME = {
  [PageName.REGISTRATION]: `Создать заявку на участие`,
  [PageName.CONTEST_ADM]: `Список участников`,
  [PageName.ORDER_TURN]: `Порядок выступлений`,
  [PageName.RESULTS]: `Итоги голосования`,
  [PageName.REFEREE]: `Голосование`,
  [PageName.CONTEST_SETTINGS]: `Настройка мероприятия`,
  [PageName.BACKDROP]: `Фон сцены`,
  [PageName.SOUNDMAN]: `Звукооператор`,
  [PageName.COMPERE]: `Конферансье`,
  [PageName.SCOREBOARD]: `Тайминг`, // `Онлайн табло
  [PageName.AUTH_DOCUMENT]: `Проверка документов`,
  [PageName.PAYMENT]: `Оплата`,
  [PageName.SCANNER]: `QR-сканер`,
  [PageName.SHOW_DOCUMENT]: `Показ документ`,
  [PageName.TITER]: `Титр`,
  [PageName.TITER_SETTINGS]: `Настройка титров`,
  [PageName.CALENDAR]: `Календарь`,
  [PageName.MONITOR]: `Диспечерская`,
  [PageName.SCENA]: `Сцена`,
  [PageName.TERMINAL]: `Терминал`,
};

export const UserRole = {
  OWNER: `owner`,
  ADMIN: `administrator`,
  REGISTRATOR: `reg`,
  // REGISTRATOR: `registrator`,
  DIRECTOR: `director`,
  PLAYER: `player`,
  ANONIM: `anon`,
  HOSTESS: `hostess`,
  COMPERE: `compere`,
  SOUNDMAN: `soundman`,
  REFEREE: `referee`,
  CUSTOM: `custom`,
  CONTACT: `contact`,
};

export const UserRoleToRus = {
  [UserRole.OWNER]: `Владелец`,
  [UserRole.ADMIN]: `Администратор`,
  [UserRole.REGISTRATOR]: `Регистратор`,
  [UserRole.DIRECTOR]: `Руководитель`,
  [UserRole.PLAYER]: `Участник`,
  [UserRole.HOSTESS]: `Хостес`,
  [UserRole.COMPERE]: `Конферансье`,
  [UserRole.SOUNDMAN]: `Звукооператор`,
  [UserRole.REFEREE]: `Судья`,
  [UserRole.CUSTOM]: `Пользовательская роль`,
};

export const EditTab = {
  PERFORMANCE: `performance`,
  TEAM: `team`,
  ORGANIZATION: `organization`,
  DIRECTORS: `directors`,
  PLAYERS: `players`,
  FILES: `files`,
  PHONOGRAMS: `phonograms`,
  STATISTIC: `statistics`,
  NOTES: `note`,
  MONEY: `money`,
  USER_FIELDS: `user_fields`,
};

export const Service = {
  ACTIVITY: `activity`,
  CONTACT: `contact`,
  TARIFF: `tariff`,
  PAYMENT: `payment`,
  DATA_TRANSFER: `data_transfer`,
  PRIVACY_POLICY: `privacy_policy`,
  TERMS_OF_USE: `terms_of_use`,
};

export const ServiceEnToServiceRus = {
  [Service.ACTIVITY]: `Наша деятельность`,
  [Service.CONTACT]: `Контакты и реквизиты`,
  [Service.TARIFF]: `Тарифы`,
  [Service.PAYMENT]: `Способы оплаты`,
  [Service.DATA_TRANSFER]: `Передача данных`,
  [Service.PRIVACY_POLICY]: `Политика конфиденциальности`,
  [Service.TERMS_OF_USE]: `Пользовательское соглашение`,
};


export const KEY_NAME = {
  TAB: `Tab`,
  ESC: `Escape`,
  ENTER: `Enter`,
  DIVIDE: `/`,
  MULTIPLY: `*`,
  SUBTRACT: `-`,
  ADD: `+`,
  KEY_Q_UP: `Q`,
  KEY_Q_LOWER: `q`,
  KEY_M_UP: `M`,
  KEY_M_LOWER: `m`,
  ARROW_UP: `ArrowUp`,
  ARROW_DOWN: `ArrowDown`,
  ARROW_LEFT: `ArrowLeft`,
  ARROW_RIGHT: `ArrowRight`,
};

export const RegExp = {
  EMAIL: /[a-zA-Z0-9._%+-]+@[A-Za-zА-Яа-яЁё0-9.-]+\.[A-Za-zА-Яа-яЁё]{2,5}$/,
};

export const PatternText = {
  CONTEST_TITLE: /\[\[contestTitle\]\]/gi,
  CONTEST_DATE: /\[\[contestDate\]\]/gi,
  CONTEST_ADDRESS: /\[\[contestAddress\]\]/gi,
  AFFIRMATION_DEADLINE: /\[\[affirmationDeadline\]\]/gi,
  PAYMENT_DEADLINE: /\[\[paymentDeadline\]\]/gi,
};

export const EmailTemplate = {
  PASSWORD: `pass`,
  MONEY_TRANSFER: `money_transfer_request`,
};

export const SearchArea = {
  CONTEST: `contest`,
  PERFORMANCE: `performance`,
  PEOPLE: `people`,
  PLAYER: `player`,
  DIRECTOR: `director`,
  TEAM: `team`,
  ORGANIZATION: `organization`,
  CITY: `city`,
};

export const TIME_ZONES = [`-12`, `-11`, `-10`, `-9`, `-8`, `-7`, `-6`, `-5`, `-4`, `-3`, `-2`, `-1`, `0`, `+1`, `+2`, `+3`, `+4`, `+5`, `+6`, `+7`, `+8`, `+9`, `+10`, `+11`, `+12`];


export const DATE_CONVERTER_FORMAT = {
  hhmm: `hour:min`,
  mmss: `min:sec`,
  hhmmss: `hour:min:sec`,
  ddmmyyyy: `day:month:year`,
  ddmmyyyyhhmmss: `day-month-year hour:min:sec`,
};

export const TariffMode = {
  PROMO: `promo`,
  PLAYER: `player`,
  PREMIUM: `premium`,
};

export const DemoMode = {
  INACTIVE: `inactive`,
  ACTIVE: `active`,
  FINISHED: `finished`,
};

export const PaymentRole = {
  ORGANIZATOR: `org`,
  SERVER: `srv`,
};

export const PaymentSystem = {
  SBERBANK: `sber`,
  TINKOFF: `tinkoff`,
  STRIPE: `stripe`,
  ROBOKASSA: `robo`,
  YANDEX: `yandex`,
  UKASSA: `ukassa`,
};

export const PaymentSystemToPaymentSystemName = {
  [PaymentSystem.SBERBANK]: `ПАО&nbsp;«Сбербанк»`,
  [PaymentSystem.STRIPE]: `«Stripe»`,
  [PaymentSystem.TINKOFF]: `АО&nbsp;«Тинькофф&nbsp;Банк»`,
  [PaymentSystem.ROBOKASSA]: `«Robokassa»`,
  [PaymentSystem.YANDEX]: `«Яндекс.Касса»`,
  [PaymentSystem.UKASSA]: `«ЮMoney»`,
};

export const TransactionStatus = {
  PROCESSING: `processing`,
  PAID: `paid`,
  CANCELED: `canceled`,
  UNKNOWN: `unknown`,
};


export const TemplateName = {
  PAY: `pay`,
  PASS: `pass`,
};


export const MoneyStatus = {
  CALCULATED: `99`, // стоимость рассчитана
  INVOICE: `100`, // счет отправлен
  FULL: `101`, // оплачено
  PART: `102`, // частично оплачено
  RETURN: `103`, // вернуть деньги
  RETURNED: `104`, // деньги возвращены
  NOT_KNOWN: `0`, // оплата неизвестна
};

export const MoneyStatusToEngText = {
  [MoneyStatus.CALCULATED]: `calculated`, // стоимость рассчитана
  [MoneyStatus.INVOICE]: `invoice`, // счет отправлен
  [MoneyStatus.FULL]: `full`, // оплачено
  [MoneyStatus.PART]: `part`, // частично оплачено
  [MoneyStatus.RETURN]: `return`, // вернуть деньги
  [MoneyStatus.RETURNED]: `returned`, // деньги возвращены
  [MoneyStatus.NOT_KNOWN]: `not known`, // оплата неизвестна
};

export const MoneyStatusToRusText = {
  [MoneyStatus.CALCULATED]: `Стоимость рассчитана`,
  [MoneyStatus.INVOICE]: `Счет отправлен`,
  [MoneyStatus.FULL]: `Оплачено полностью`,
  [MoneyStatus.PART]: `Частично оплачено`,
  [MoneyStatus.RETURN]: `Вернуть деньги`,
  [MoneyStatus.RETURNED]: `Деньги возвращены`,
  [MoneyStatus.NOT_KNOWN]: `Оплата неизвестна`,
};

export const MoneyStatusToIcon = {
  [MoneyStatus.CALCULATED]: `invoice`,
  [MoneyStatus.INVOICE]: `invoice`,
  [MoneyStatus.FULL]: `paid`,
  [MoneyStatus.PART]: `notPaid`,
  [MoneyStatus.RETURN]: `overpaid`,
  [MoneyStatus.RETURNED]: `paid`,
  [MoneyStatus.NOT_KNOWN]: `unknown`,
};

export const MailServer = {
  PRIMETIME: `primetime`,
  YANDEX: `yandex`,
  GMAIL: `gmail`,
  MAIL: `mail`,
  ICLOUD: `icloud`,
  OUTLOOK: `outlook`,
  YAHOO: `yahoo`,
  AOL: `aol`,
  RAMBLER: `rambler`,
};

export const MailServerIds = {
  [MailServer.PRIMETIME]: 1,
  [MailServer.YANDEX]: 2,
  [MailServer.GMAIL]: 3,
  [MailServer.MAIL]: 4,
  [MailServer.ICLOUD]: 5,
  [MailServer.OUTLOOK]: 6,
  [MailServer.YAHOO]: 7,
  [MailServer.AOL]: 8,
  [MailServer.RAMBLER]: 9,
};

export const MailServers = {
  [`1`]: MailServer.PRIMETIME,
  [`2`]: MailServer.YANDEX,
  [`3`]: MailServer.GMAIL,
  [`4`]: MailServer.MAIL,
  [`5`]: MailServer.ICLOUD,
  [`6`]: MailServer.OUTLOOK,
  [`7`]: MailServer.YAHOO,
  [`8`]: MailServer.AOL,
  [`9`]: MailServer.RAMBLER,
};

export const PaymentSystemId = {
  [PaymentSystem.SBERBANK]: `1`,
  [PaymentSystem.STRIPE]: `2`,
  [PaymentSystem.TINKOFF]: `3`,
  [PaymentSystem.ROBOKASSA]: `4`,
  [PaymentSystem.UKASSA]: `5`,
  // [PaymentSystem.YANDEX]: `5`,
};

export const FileType = {
  LINK: `link`,
  LIVE_LINK: `live_link`,
  VIDEO: `video`,
  IMAGE: `image`,
  PDF: `pdf`,
  UNKNOWN: `unknown`,
};

export const DEFAULT_FULL_NAME = {
  surname: ``,
  lastname: ``,
  middlename: ``,
};

export const PeopleMode = {
  ADD: `add`,
  DELETE: `delete`,
  REPLACE: `replace`,
  EDIT: `edit`,
};

export const EmailStatus = {
  UNDEFINED: `undefined`,
  OK: `ok`,
  NOT_EXIST: `not_exist`,
  FULL: `full`,
};

export const TerminalPage = {
  SHOW_DOCUMENT: `show_document`,
  SCOREBOARD: `scoreboard`,
  SEMAPHORE: `semaphore`,
  TITER: `titer`,
  TITER_SETTINGS: `titer_settings`,
  BACKDROP: `backdrop`,
  SCANNER: `scanner`,
  SCENA: `scena`,
  // потом добавить - онлайн-трансляция оценок судей;
};

export const TerminalPageEnToTerminalPageRus = {
  [TerminalPage.SHOW_DOCUMENT]: `Демонстрация проекта диплома участнику на дисплее`,
  [TerminalPage.SCOREBOARD]: `Текущий тайминг в фойе на экране`,
  [TerminalPage.SEMAPHORE]: `Электронный контролёр/лампочка`,
  [TerminalPage.TITER]: `Автоматические титры для онлайн-трансляций`,
  [TerminalPage.TITER_SETTINGS]: `Удаленная настройка параметров отображения титров в трансляции`,
  [TerminalPage.BACKDROP]: `Интерактивный «задник» сцены`,
  [TerminalPage.SCANNER]: `«Умные билеты» (QR-коды для контроля участников)`,
  [TerminalPage.SCENA]: `Сцена`,
};

export const TERMINAL_PAGES = [
  {
    id: TerminalPage.SHOW_DOCUMENT,
    text: TerminalPageEnToTerminalPageRus[TerminalPage.SHOW_DOCUMENT],
  }, {
    id: TerminalPage.SCOREBOARD,
    text: TerminalPageEnToTerminalPageRus[TerminalPage.SCOREBOARD],
  }, {
    id: TerminalPage.SEMAPHORE,
    text: TerminalPageEnToTerminalPageRus[TerminalPage.SEMAPHORE],
  }, {
    id: TerminalPage.TITER,
    text: TerminalPageEnToTerminalPageRus[TerminalPage.TITER],
  }, {
    id: TerminalPage.TITER_SETTINGS,
    text: TerminalPageEnToTerminalPageRus[TerminalPage.TITER_SETTINGS],
  }, {
    id: TerminalPage.BACKDROP,
    text: TerminalPageEnToTerminalPageRus[TerminalPage.BACKDROP],
  }, {
    id: TerminalPage.SCANNER,
    text: TerminalPageEnToTerminalPageRus[TerminalPage.SCANNER],
  }, {
    id: TerminalPage.SCENA,
    text: TerminalPageEnToTerminalPageRus[TerminalPage.SCENA],
  },
];

// https://ru.wikipedia.org/wiki/%D0%9A%D0%BE%D0%B4%D1%8B_%D1%8F%D0%B7%D1%8B%D0%BA%D0%BE%D0%B2
export const Language = {
  RU: `ru`,
  EN: `en`,
  KK: `kk`,
  DE: `de`,
  KO: `ko`,
  ZH: `zh`,
};

export const LanguageToRusText = {
  [Language.RU]: `русский`,
  [Language.EN]: `английский`,
  [Language.KK]: `казахский`,
  [Language.DE]: `немецкий`,
  [Language.KO]: `корейский`,
  [Language.ZH]: `китайский`,
};

export const LanguageToEngText = {
  [Language.RU]: `russian`,
  [Language.EN]: `english`,
  [Language.KK]: `kazakh`,
  [Language.DE]: `german`,
  [Language.KO]: `korean`,
  [Language.ZH]: `chinese`,
};

export const Case = {
  NOMINATIVE: `ru`,
  GENITIVE: `ru_r`,
  DATIVE: `ru_d`,
  // ACCUSATIVE: `ru_v`,
  INSTRUMENTAL: `ru_t`,
  // PREPOSITIONAL: `ru_p`
};

export const CasesToText = {
  [Case.NOMINATIVE]: `Именительный (кто? что?)`,
  [Case.GENITIVE]: `Родительный (кого? чего?)`,
  [Case.DATIVE]: `Дательный (кому? чему?)`,
  [Case.ACCUSATIVE]: `Винительный (кого? что?)`,
  [Case.INSTRUMENTAL]: `Творительный (кем? чем?)`,
  [Case.PREPOSITIONAL]: `Предложный (о ком? о чем?)`,
};

export const Alias = {
  RU: [Language.RU],
  RU_R: [Case.GENITIVE],
  RU_D: [Case.DATIVE],
  RU_V: [Case.ACCUSATIVE],
  RU_T: [Case.INSTRUMENTAL],
  RU_P: [Case.PREPOSITIONAL],
  EN: [Language.EN],
  KK: [Language.KK],
  DE: [Language.DE],
};

export const AliasToText = {
  [Alias.RU]: `Русский язык (именительный падеж)`,
  [Alias.RU_R]: `Русский язык (родительный падеж)`,
  [Alias.RU_D]: `Русский язык (дательный падеж)`,
  [Alias.RU_V]: `Русский язык (винительный падеж)`,
  [Alias.RU_T]: `Русский язык (творительный падеж)`,
  [Alias.RU_P]: `Русский язык (предложный падеж)`,
  [Alias.EN]: `Английский язык`,
  [Alias.KK]: `Казахский язык`,
  [Alias.DE]: `Немецкий язык`,
};

export const AliasToShortText = {
  [Alias.RU]: `РУС(и.п.)`,
  [Alias.RU_R]: `РУС(р.п.)`,
  [Alias.RU_D]: `РУС(д.п.)`,
  [Alias.RU_V]: `РУС(в.п.)`,
  [Alias.RU_T]: `РУС(т.п.)`,
  [Alias.RU_P]: `РУС(п.п.)`,
  [Alias.EN]: `АНГ`,
  [Alias.KK]: `КАЗ`,
  [Alias.DE]: `НЕМ`,
};

export const NameType = {
  SURNAME: `surname`,
  LASTNAME: `lastname`,
  MIDDLENAME: `middlename`
};

export const NameTypeByRuAccCase = {
  [NameType.SURNAME]: `фамилию`,
  [NameType.LASTNAME]: `имя`,
  [NameType.MIDDLENAME]: `отчество`,
};

export const DEFAULT_PEOPLE = {
  mode: PeopleMode.ADD,
  peopleId: ``,
  surname: ``,
  lastname: ``,
  middlename: ``,
  phones: [],
  emails: [],
};

export const DEFAULT_DIRECTOR = {
  mode: `add`,
  peopleId: ``,
  surname: ``,
  lastname: ``,
  middlename: ``,
  positions: [],
  phones: [],
  emails: [],
  contactId: ``,
  type: `руководитель`,
};

export const DEFAULT_PLAYER = {
  mode: `add`,
  peopleId: ``,
  surname: ``,
  lastname: ``,
  middlename: ``,
  dob: ``,
  phones: [],
  emails: [],
  isDiplom: false,
  playerId: ``,
  title: ``,
  contactId: ``,
  type: `участник`,
};

export const DEFAULT_CONTACT = {
  mode: `add`,
  peopleId: ``,
  surname: ``,
  lastname: ``,
  middlename: ``,
  phones: [],
  emails: [],
  type: `контактное лицо`,
};

export const DEFAULT_NOMINATION = {
  nominationId: ``,
  parentId: `0`,
  title: ``,
};

export const DEFAULT_AGE_CATEGORY = {
  ageCategoryId: ``,
  title: ``,
};

export const DEFAULT_QUALIFICATION = {
  qualificationId: ``,
  title: ``,
};

export const NO_REPRESENT_ORGANIZATION_ID = `0`;

export const DEFAULT_PERFORMANCE = {
  performanceId: ``,
  title: ``,
  // city: ``,
  // cityId: ``,
  // isCityPrint: false,
  // isRepresent: false,
};

export const DEFAULT_ORGANIZATION = {
  organizationId: ``,
  title: ``,
  city: ``,
  cityId: ``,
  isCityPrint: false,
  isRepresent: false,
};

export const DEFAULT_NO_REPRESENT_ORGANIZATION = {
  organizationId: `0`,
  title: ``,
  city: ``,
  cityId: ``,
};

export const NO_REPRESENT_TEAM_ID = `0`;

export const DEFAULT_TEAM = {
  teamId: ``,
  title: ``,
  city: ``,
  cityId: ``,
  isCityPrint: false,
  isRepresent: false,
};

export const DEFAULT_NO_REPRESENT_TEAM = {
  teamId: `0`,
  title: ``,
  city: ``,
  cityId: ``,
};

export const DEFAULT_CITY = {
  city: ``,
  cityId: ``,
  googleId: ``,
};

export const FILE_DEFAULT = {
  fileId: null,
  name: ``,
};

export const VIDEO_DEFAULT = {
  link: ``,
  preview: ``
};

export const PHONOGRAM_DEFAULT = {
  phonogramId: null,
  name: ``,
  duration: 0,
};

export const DEFAULT_PHONOGRAM = {
  phonogramId: ``,
  title: ``,
  format: ``,
  link: ``,
  duration: ``,
  startPoint: {
    name: `Не указано`,
    id: `null`
  },
  status: {
    name: `Не загружена`,
    id: `none`,
    info: `base`
  }
};

export const DEFAULT_START_POINT = {
  startPointId: ``,
  title: ``,
};

export const START_POINTS = [
  {
    startPointId: `0`,
    title: `из кулис`,
  }, {
    startPointId: `1`,
    title: `с точки`,
  }, {
    startPointId: `none`,
    title: `сообщу позднее`,
  }
];
export const PHONOGRAM_STATUSES = [
  {
    id: `5`,
    text: `Принята`,
  },
  {
    id: `0`,
    text: `Не проверена`,
  },
  {
    id: `10`,
    text: `Отклонена`
  },
  {
    id: `11`,
    text: `Отклонена: низкое качество звука`
  },
];

export const DEFAULT_NOTE = {
  text: ``,
  fullText: ``,
};

export const ClearMode = {
  FORM: `form`,
  TAB: `tab`,
};

export const ModePeoplePopup = {
  SEARCH: `search`,
  MANUAL: `manual`,
};

export const ModeRenderWrap = {
  ALL: `all`,
  ONE: `one`,
};

export const ButtonsPosition = {
  BOTTOM: `bottom`,
  RIGHT: `right`
};

export const ValidateMode = {
  PASSIVE: `passive`,
  ACTIVE: `active`,
};

export const MultiWrapInputRequiredMode = {
  NONE: `none`,
  ONE: `one`,
  ALL: `all`,
};


export const RegistrationField = {
  AUTHORIZATION: `authorization`,
  CONTEST: `contest`,
  PERFORMANCE: `performance`,

  NOMINATION: `nomination`,
  AGE_CATEGORY: `age_category`,
  QUALIFICATION: `qualification`,

  ORGANIZATION: `organization`,
  TEAM: `team`,
  CITY: `city`,

  PLAYERS: `players`,
  DIRECTORS: `directors`,
  CONTACT: `contact`,

  FILES: `files`,

  FILE: `file`,
  LINK: `link`,
  PHONOGRAMS: `phonograms`,

  START_POINT: `start_point`,
  DURATION: `duration`,
  NOTE: `note`,

  PAYMENT: `payment`,
  REGISTRATION_POPUP: `registration_popup`,

  COMMON: `common`,
};

export const ComponentType = {
  FIELD: `field`,
  USER_FIELD: `userField`,
  TEXT: `text`,
  LINK_BUTTON: `linkButton`,
  SEPARATOR: `separator`,
  IMAGE: `image`,
};


export const ButtonTitle = {
  NO: `Нет`,
  OK: `ОК`,
  ADD: `Добавить`,
  YES: `Да`,
  RUN: `Запустить`,
  BACK: `Назад`,
  EDIT: `Редактировать`,
  MOVE: `Переместить`,
  COPY: `Копировать`,
  SEND: `Отправить`,
  SAVE: `Сохранить`,
  LOAD: `Загрузить`,
  VIEW: `Просмотр`,
  APPLY: `Применить`,
  RESET: `Сбросить`,
  CLEAR: `Очистить`,
  CLOSE: `Закрыть`,
  PRINT: `Печать`,
  CANCEL: `Отмена`,
  CREATE: `Создать`,
  CHOICE: `Выбрать`,
  DELETE: `Удалить`,
  CHANGE: `Изменить`,
  REPLACE: `Заменить`,
  PROCEED: `Продолжить`,
  PREPARE: `Подготовить`,
  CONTINUE: `Далее`,
  DOWNLOAD: `Скачать`,
  REPLACE_ALL: `Заменить все`,
};

export const OrientationDocument = {
  PORTRAIT: `P`,
  LANDSCAPE: `L`,
};

export const OrientationDocumentEnToOrientationDocumentRus = {
  [OrientationDocument.PORTRAIT]: `Книжная`,
  [OrientationDocument.LANDSCAPE]: `Альбомная`,
};

export const FormatDocument = {
  A3: `A3`,
  A4: `A4`,
  A5: `A5`,
  Letter: `Letter`,
  Legal: `Legal`,
};

export const SizeFormat = {
  [FormatDocument.A3]: [297, 420],
  [FormatDocument.A4]: [210, 297],
  [FormatDocument.A5]: [148, 210],
  [FormatDocument.Letter]: [215.9, 279.4],
  [FormatDocument.Legal]: [215.9, 355.6],
};

export const CUSTOM_FORMAT = `custom`;

export const FormatDocumentEnToFormatDocumentRus = {
  [FormatDocument.A3]: `A3 (297 x 420 мм)`,
  [FormatDocument.A4]: `A4 (210 x 297 мм)`,
  [FormatDocument.A5]: `A5 (148 x 210 мм)`,
  [FormatDocument.Letter]: `Letter (215.9 x 279.4 мм)`,
  [FormatDocument.Legal]: `Legal (215.9 x 355.6 мм)`,
  [CUSTOM_FORMAT]: `Свой размер листа`,
};

export const DIRECTORS_TEXT_BLOCKS = [
  {
    note: `Кнопка добавления руководителя`,
    text: `Добавить руководителя`,
  },
  {
    note: `Текст, когда еще не добавлены руководители, между кнопкой добавления и галочкой`,
    text: `Или если у Вас не руководителя, то поставьте галочку`,
  },
  {
    note: `Текст для галочки, когда нет руководителя`,
    text: `У меня нет руководителя`,
  },
  {
    note: `Заголовок всплывающего окна при добавлении руководителя`,
    text: `Добавление руководителя`,
  },
  {
    note: `Заголовок всплывающего окна при замене руководителя`,
    text: `Замена руководителя`,
  },
  {
    note: `Заголовок всплывающего окна при редактировании данных руководителя`,
    text: `Редактирование данных руководителя`,
  },
  {
    note: `Заголовок всплывающего окна при удалении руководителя`,
    text: `Удаление руководителя`,
  },
  {
    note: `Текст всплывающего окна при удалении руководителя (после фразы идет ФИО руководителя и знак вопроса)`,
    text: `Вы уверены, что хотите удалить руководителя`,
  },
  {
    note: `Текст ошибки, если не добавлен руководителя или не поставлена галочка`,
    text: `Добавьте руководителя, или поставьте галочку «У меня нет руководителя»`,
  },
];

export const PLAYERS_TEXT_BLOCKS = [
  {
    note: `Текст перед полем количества участников`,
    text: `Укажите число участников`,
  },
  {
    note: `Текст перед блоками с данными по каждому участнику`,
    text: `Укажите данные каждого участника`,
  },
  {
    note: `Текст на блоке для ввода данных по каждому участнику`,
    text: `Укажите данные участника`,
  },
  {
    note: `Заголовок всплывающего окна при добавлении участника`,
    text: `Добавление участника`,
  },
  {
    note: `Заголовок всплывающего окна при замене участника`,
    text: `Замена участника`,
  },
  {
    note: `Заголовок всплывающего окна при редактировании данных участника`,
    text: `Редактирование данных участника`,
  },
  {
    note: `Заголовок всплывающего окна при удалении участника`,
    text: `Удаление участника`,
  },
  {
    note: `Текст всплывающего окна при удалении участника (после фразы идет ФИО участника и знак вопроса)`,
    text: `Вы уверены, что хотите удалить участника`,
  },
  {
    note: `Текст ошибки, когда не указано количество участников`,
    text: `Укажите число участников`,
  },
  {
    note: `Текст ошибки, когда не введены данные по всем участникам`,
    text: `Введите данные по всем участникам, или уменьшите их количество`,
  },
];

export const FILES_TEXT_BLOCKS = [
  {
    note: `Текст подписи блока`,
    text: `Если сейчас Вы не готовы или не смогли загрузить конкурсную работу или ссылку на нее, Вы сможете сделать это позднее. Просто зайдите на этот сайт <a href="/">PrimeTime.today</a>, используя логин и пароль, которые уже пришли на указанный Вами адрес электронной почты.`,
  },
  {
    note: `Текст ошибки, если не загружен файл и/или не добавлена ссылка`,
    text: `Загрузите файл или укажите ссылку`,
  },
  {
    note: `Текст ошибки, если загружаемый файл превышает максимальный объем файла [[file_max_size]]`,
    text: `Загрузите файл не больше [[file_max_size]] Мбайт`,
  },
  {
    note: `Текст ошибки, если расширение загружаемого файла не соответствует допустимым форматам [[file_formats]]`,
    text: `Загрузите файл в формате [[file_formats]]`,
  },
];


export const PHONOGRAMS_TEXT_BLOCKS = [
  {
    note: `Текст, если фонограмма не требуется`,
    text: `Фонограмма не требуется`,
  },
  {
    note: `Текст для поля для загрузки фонограммы`,
    text: `Перетащите фонограмму сюда или`,
  },
  {
    note: `Текст кнопки для загрузки фонограммы`,
    text: `Выберите из папки`,
  },
  {
    note: `Текст ошибки, если фонограммма не загружена`,
    text: `Загрузите фонограмму`,
  },
  {
    note: `Текст кнопки для удаления фонограммы`,
    text: `Удалить фонограмму`,
  },
  // {
  //   note: `Текст ошибки, если фонограммма загружена не в формате mp3`,
  //   text: `Загрузите файл в формате .mp3`,
  // },
];

export const CONTACT_TEXT_BLOCKS = [
  {
    note: `Текст кнопки для добавления`,
    text: `+ Добавить человека`,
  },
  {
    note: `Текст ошибки, если не выбрано контактное лицо`,
    text: `Выберите контактное лицо, или введите его данные`,
  },
  {
    note: `ТЕкст ошибки, если не указана электронная почта`,
    text: `Укажите электронную почту!`,
  },
];

export const CITY_TEXT_BLOCKS = [
  {
    note: `Текст для пункта меню, если нет города в предложенном списке`,
    text: `Нет подходящего, выбрать из расширенного списка`,
  },
  {
    note: `Текст для всплывающего окна выбора населенного пункта`,
    text: `Выберите населенный пункт`,
  },
  {
    note: `Текст для ошибки при выборе населенного пункта`,
    text: `Выберите город из выпадающего списка`,
  },
];

export const AUTH_TEXT_BLOCKS = [
  {
    note: `Текст для указания ФИО контактного лица`,
    text: `<b>Представьтесь, как Вас зовут?</b><br><span style="font-size: 0.8em;"> 1. Укажите Ваши Фамилию Имя и Отчество (если есть)<br> 2. Выберите себя из списка или нажмите кнопку «Меня нет в списке»</span>`,
  }
];

export const ScreenConstructor = {
  STEP1: 1,
  STEP2: 2,
  STEP3: 3,
  STEP4: 4,
  STEP5: 5,
};

export const TemplateRF = {
  PAGE: `page`,
  TABS: `tabs`,
  BASE: `base`,
  CLASSIC: `classic`,
};

export const TemplatePayment = {
  NONE: `none`,
  ORGANIZATOR: `organizator`,
  // SERVER: `service`,
};

export const CountColumn = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
};

export const TemplateRFEnToTemplateRFRus = {
  [TemplateRF.PAGE]: `Страница`,
  [TemplateRF.TABS]: `Вкладки`,
  [TemplateRF.BASE]: `Базовый`,
  [TemplateRF.CLASSIC]: `Стандартный`,
};

export const TemplatePaymentEnToTemplatePaymentRus = {
  [TemplatePayment.NONE]: `Не принимать платежи`,
  [TemplatePayment.ORGANIZATOR]: `Принимать оплату для Организатора`,
  [TemplatePayment.SERVER]: `Принимать оплату для PrimeTime.today`,
};

export const TemplatePaymentToPaymentRole = {
  [TemplatePayment.ORGANIZATOR]: PaymentRole.ORGANIZATOR,
  [TemplatePayment.SERVER]: PaymentRole.SERVER,
};

export const CountColumnEnToCountColumnRus = {
  [CountColumn.ONE]: `Одна`,
  [CountColumn.TWO]: `Две`,
  [CountColumn.THREE]: `Три`,
  [CountColumn.FOUR]: `Четыре`,
};

export const BaseBlock = {
  TEXT: `text`,
  BUTTON: `button`,
  SEPARATOR: `separator`,
  IMAGE: `image`,
};

export const BaseBlockEnToBaseBlockRus = {
  [BaseBlock.SEPARATOR]: `Разделитель`,
  [BaseBlock.TEXT]: `Текстовый блок`,
  [BaseBlock.BUTTON]: `Кнопка-ссылка`,
  [BaseBlock.IMAGE]: `Изображение`,
};

export const REGISTRATION_BASE_BLOCKS = [
  {
    name: BaseBlock.SEPARATOR,
    type: ComponentType.SEPARATOR,
    setting: {
      title: BaseBlockEnToBaseBlockRus[BaseBlock.SEPARATOR],
    }
  }, {
    name: BaseBlock.TEXT,
    type: ComponentType.TEXT,
    setting: {
      componentId: ``,
      title: BaseBlockEnToBaseBlockRus[BaseBlock.TEXT],
      pattern: `Может быть любой текст`,
      position: `center`,
      style: `normal`,
      ifElse: ``,
    }
  }, {
    name: BaseBlock.BUTTON,
    type: ComponentType.LINK_BUTTON,
    setting: {
      componentId: ``,
      title: BaseBlockEnToBaseBlockRus[BaseBlock.BUTTON],
      link: ``,
      color: `#999999`,
      position: `center`,
    }
  }, {
    name: BaseBlock.IMAGE,
    type: ComponentType.IMAGE,
    setting: {
      componentId: ``,
      title: BaseBlockEnToBaseBlockRus[BaseBlock.IMAGE],
      src: ``,
      position: `center`,
    }
  },
];

export const RegistrationFieldEnToRegistrationFieldRus = {
  [RegistrationField.CONTEST]: `Мероприятие`,
  [RegistrationField.AUTHORIZATION]: `Представьтесь`,
  [RegistrationField.PERFORMANCE]: `Название выступления`,
  [RegistrationField.NOMINATION]: `Номинация`,
  [RegistrationField.AGE_CATEGORY]: `Возрастная категория`,
  [RegistrationField.QUALIFICATION]: `Квалификация (Устарело! Не использовать!)`,
  [RegistrationField.ORGANIZATION]: `Название организации`,
  [RegistrationField.TEAM]: `Название коллектива`,
  [RegistrationField.CITY]: `Населенный пункт`,
  [RegistrationField.DIRECTORS]: `Руководители`,
  [RegistrationField.PLAYERS]: `Участники`,
  [RegistrationField.CONTACT]: `Контактное лицо`,
  [RegistrationField.FILES]: `Загрузка файлов (Устарело! Не использовать!)`,
  [RegistrationField.FILE]: `Файлы`,
  [RegistrationField.LINK]: `Ссылки`,
  [RegistrationField.PHONOGRAMS]: `Фонограммы`,
  [RegistrationField.START_POINT]: `Начало выступления`,
  [RegistrationField.DURATION]: `Длительность выступления`,
  [RegistrationField.PAYMENT]: `Оплата`,
  [RegistrationField.NOTE]: `Комментарий`,
  [RegistrationField.REGISTRATION_POPUP]: `Окно после нажатия кнопки «Отправить заявку»`,
  [RegistrationField.COMMON]: ``,
};

export const NameCheckboxEnToNameCheckboxRus = {
  'isRequired': `Обязательность поля`,
  'isShowAddress': `Показывать адрес`,
  'isShowOrganizationLink': `Показвать кнопку «Все конкурсы организатора»`,
  'isShowDateContest': `Показывать даты мероприятия`,
  'isShowPattern': `Использовать шаблон`,
  'isShowMiddlename': `Показывать поле Отчество`,
  'isMiddlenameRequired': `Обязательность поля Отчество`,
  'isShowEmail': `Показывать поле Email`,
  'isRequiredEmail': `Обязательность поля Email`,
  'isShowPhone': `Показывать поле Телефон`,
  'isRequiredPhone': `Обязательность поля Телефон`,
  'isShowFile': `Показывать поле для загрузка файлов с устройства`,
  'isShowLink': `Показывать поле для ссылок`,
  'isShowCheckboxIsNoDirector': `Показывать «У меня нет руководителя»`,
  'isSaveDataAfterRegistration': `Сохранять данные после подачи заявки`,
  'isReadOnlyPositions': `Запретить ручной ввод должности`,
};

export const SceneBlockType = {
  GROUP: `group`,
  BLOCK: `block`,
  TEXT: `text`,
  VIDEO: `video`,
  COUNTDOWN: `countdown`,
};

export const ShowEndMode = {
  // COMMAND: `command`,
  STOP_PERFORMANCE_ALERT: AlertType.PERFORMANCE_STOP,
  TIME: `time`,
};

export const ShowEndModeEnToRus = {
  [`command`]: `По оповещению «Финиш»`,
  [ShowEndMode.STOP_PERFORMANCE_ALERT]: `По оповещению «Финиш»`,
  [ShowEndMode.TIME]: `По времени`,
};

export const TextPosition = {
  CENTER: `center`,
  LEFT: `left`,
  RIGHT: `right`,
};

export const TextPositionEnToTextPositionRus = {
  [TextPosition.CENTER]: `По центру`,
  [TextPosition.LEFT]: `По левому краю`,
  [TextPosition.RIGHT]: `По правому краю`,
};

export const TextVerticalPosition = {
  CENTER: `center`,
  TOP: `top`,
  BOTTOM: `bottom`,
};

export const TextVerticalPositionEnToRus = {
  [TextVerticalPosition.CENTER]: `По центру`,
  [TextVerticalPosition.TOP]: `По верхнему краю`,
  [TextVerticalPosition.BOTTOM]: `По нижнему краю`,
};

export const TEXT_POSITIONS = Object.keys(TextPosition).map((key) => {
  return {
    id: TextPosition[key],
    text: TextPositionEnToTextPositionRus[TextPosition[key]],
  };
});

export const BlockPosition = {
  CENTER: `center`,
  TOP_LEFT: `top-left`,
  TOP_RIGHT: `top-right`,
  BOTTOM_LEFT: `bottom-left`,
  BOTTOM_RIGHT: `bottom-right`,
};

export const BlockPositionEnToBlockPositionRus = {
  [BlockPosition.TOP_LEFT]: `Вверху слева`,
  [BlockPosition.TOP_RIGHT]: `Вверху справа`,
  [BlockPosition.BOTTOM_LEFT]: `Внизу слева`,
  [BlockPosition.BOTTOM_RIGHT]: `Внизу справа`,
  [BlockPosition.CENTER]: `По центру`,
};

export const BLOCK_POSITIONS = Object.keys(BlockPosition).map((key) => {
  return {
    id: BlockPosition[key],
    text: BlockPositionEnToBlockPositionRus[BlockPosition[key]],
  };
});

export const TextStyle = {
  NORMAL: `normal`,
  BOLD: `bold`,
  ITALIC: `italic`,
  BOLD_ITALIC: `bold_italic`,
};

export const TextStyleEnToRus = {
  [TextStyle.NORMAL]: `Обычный`,
  [TextStyle.BOLD]: `Жирный`,
  [TextStyle.ITALIC]: `Курсив`,
  [TextStyle.BOLD_ITALIC]: `Жирный курсив`,
};

export const TEXT_STYLES = Object.keys(TextStyle).map((key) => {
  return {
    id: TextStyle[key],
    text: TextStyleEnToRus[TextStyle[key]],
  };
});


export const BlockRotate = {
  NOT_ROTATE: `not rotate`,
  CLOCKWISE_PER_90_DEG: `CW_90`,
  COUNTER_CLOCKWISE_PER_90_DEG: `CCW_90`,
  CLOCKWISE_PER_180_DEG: `CW_180`,
};

export const BlockRotateEnToBlockRotateRus = {
  [BlockRotate.NOT_ROTATE]: `Не поворачивать`,
  [BlockRotate.CLOCKWISE_PER_90_DEG]: `Повернуть на 90 по часовой`,
  [BlockRotate.COUNTER_CLOCKWISE_PER_90_DEG]: `Повернуть на 90 против часовой`,
  [BlockRotate.CLOCKWISE_PER_180_DEG]: `Повернуть на 180`,
};

export const BLOCK_ROTATES = Object.keys(BlockRotate).map((key) => {
  return {
    id: BlockRotate[key],
    text: BlockRotateEnToBlockRotateRus[BlockRotate[key]],
  };
});


export const ImageFit = {
  COVER: `cover`,
  CONTAIN: `contain`,
  NO_SCALE: `no-scale`,
  FILL: `fill`,
};

export const ImageFitEnToRus = {
  [ImageFit.COVER]: `По ширине`,
  [ImageFit.CONTAIN]: `По высоте`,
  [ImageFit.NO_SCALE]: `Не масштабировать`,
  [ImageFit.FILL]: `В размер`,
};

export const IMAGE_FITS = Object.keys(ImageFit).map((key) => {
  return {
    id: ImageFit[key],
    text: ImageFitEnToRus[ImageFit[key]],
  };
});

export const RefereeBackdropMode = {
  NONE: `none`,
  PERSONAl: `personal`,
  SERIAL: `serial`,
};

export const RefereeBackdropModeEnToRus = {
  [RefereeBackdropMode.NONE]: `Не показывать`,
  [RefereeBackdropMode.PERSONAL]: `С фамилией`,
  [RefereeBackdropMode.SERIAL]: `С порядковыми номерами («Судья 1», «Судья 2» и т.д.)`,
};

export const REFEREE_BACKDROP_MODE = [
  {
    id: RefereeBackdropMode.NONE,
    text: RefereeBackdropModeEnToRus[RefereeBackdropMode.NONE],
  }, {
  //   id: RefereeBackdropMode.PERSONAL,
  //   text: RefereeBackdropModeEnToRefereeBackdropModeRus[RefereeBackdropMode.PERSONAL],
  // }, {
    id: RefereeBackdropMode.SERIAL,
    text: RefereeBackdropModeEnToRus[RefereeBackdropMode.SERIAL],
  },
];

export const RefereeLiveLinkBackdropShowRule = {
  NONE: `none`,
  ALWAYS: `always`,
  RATING: `rating`,
};

export const RefereeLiveLinkBackdropShowRuleEnToRus = {
  [RefereeLiveLinkBackdropShowRule.NONE]: `Не показывать`,
  [RefereeLiveLinkBackdropShowRule.ALWAYS]: `Всегда`,
  [RefereeLiveLinkBackdropShowRule.RATING]: `При показе оценок`,
};

export const REFEREE_LIVE_LINK_BACKDROP_SHOW_RULES = Object.keys(RefereeLiveLinkBackdropShowRule).map((key) => {
  return {
    id: RefereeLiveLinkBackdropShowRule[key],
    text: RefereeLiveLinkBackdropShowRuleEnToRus[RefereeLiveLinkBackdropShowRule[key]],
  };
});


export const UserFieldType = {
  TEXT: `text`,
  DROPDOWN: `dropDown`,
  NUMBER: `number`,
  DATE: `date`,
  DATETIME: `datetime`,
  PEOPLE: `people`,
  FILE: `file`,
  LINK: `link`,
  PHONOGRAM: `phonogram`,
};


export const REGISTRATION_FORM_FIELDS = [
  {
    name: RegistrationField.PERFORMANCE,
    setting: {
      componentId: ``,
      title: `Название выступления`,
      isRequired: true,
      placeholder: ``,
      qualification: ``,
      ifElse: ``,
    }
  },
  {
    name: RegistrationField.NOMINATION,
    setting: {
      title: `Номинация`,
      subtitleLevel1: `Подноминация`,
      subtitleLevel2: `Подноминация`,
      subtitleLevel3: `Подноминация`,
      subtitleLevel4: `Подноминация`,
      subtitleLevel5: `Подноминация`,
      isRequired: false,
      isSaveDataAfterRegistration: true,
      placeholder: ``,
      ifElse: ``,
    }
  },
  {
    name: RegistrationField.AGE_CATEGORY,
    setting: {
      title: `Возрастная категория`,
      isRequired: false,
      isSaveDataAfterRegistration: true,
      placeholder: ``,
      ifElse: ``,
    }
  },
  {
    name: RegistrationField.QUALIFICATION,
    setting: {
      title: `Квалификация (Устарело! Не использовать!)`,
      isRequired: false,
      placeholder: ``,
      ifElse: ``,
    }
  },
  {
    name: RegistrationField.ORGANIZATION,
    setting: {
      title: `Название организации`,
      isRequired: false,
      textBlocks: [
        {
          note: `Текст для галочки, когда не представляют организацию`,
          text: `Я не представляю организацию`,
        },
      ],
      ifElse: ``,
    }
  },
  {
    name: RegistrationField.TEAM,
    setting: {
      title: `Название коллектива`,
      isRequired: false,
      textBlocks: [
        {
          note: `Текст для галочки, когда не представляют коллектив`,
          text: `Я не представляю коллектив`,
        },
      ],
      ifElse: ``,
    }
  },
  {
    name: RegistrationField.CITY,
    setting: {
      title: `Населенный пункт`,
      isRequired: false,
      placeholder: ``,
      textBlocks: CITY_TEXT_BLOCKS,
      ifElse: ``,
    }
  },
  {
    name: RegistrationField.DIRECTORS,
    setting: {
      title: `Руководители`,
      isRequired: true,
      positions: [],
      isShowCheckboxIsNoDirector: true,
      isShowMiddlename: true,
      isMiddlenameRequired: true,
      isShowEmail: true,
      isRequiredEmail: false,
      isShowPhone: true,
      isRequiredPhone: false,
      isReadOnlyPositions: false,
      textBlocks: DIRECTORS_TEXT_BLOCKS,
      ifElse: ``,
    }
  },
  {
    name: RegistrationField.PLAYERS,
    setting: {
      title: `Участники`,
      isRequired: true,
      isShowMiddlename: true,
      isMiddlenameRequired: false,
      isShowEmail: true,
      isRequiredEmail: false,
      isShowPhone: true,
      isRequiredPhone: false,
      isSaveDataAfterRegistration: true,
      textBlocks: PLAYERS_TEXT_BLOCKS,
      ifElse: ``,
    }
  },
  {
    name: RegistrationField.CONTACT,
    setting: {
      title: `Контактное лицо`,
      isRequired: false,
      placeholder: ``,
      textBlocks: CONTACT_TEXT_BLOCKS,
      ifElse: ``,
    }
  },
  {
    name: RegistrationField.FILES,
    setting: {
      componentId: ``,
      title: `Загрузка файлов (Устарело! Не использовать!)`,
      isRequired: false,
      isShowFile: true,
      isShowLink: true,
      textBlocks: FILES_TEXT_BLOCKS,
      maxFileSize: Infinity,
      formats: [],
      ifElse: ``,
    }
  },
  {
    name: RegistrationField.FILE,
    setting: {
      componentId: ``,
      title: `Файлы`,
      isRequired: false,
      userFieldId: ``,
      textBlocks: FILES_TEXT_BLOCKS,
      maxFileSize: Infinity,
      formats: [],
      ifElse: ``,
    }
  },
  {
    name: RegistrationField.LINK,
    setting: {
      componentId: ``,
      title: `Ссылки`,
      isRequired: false,
      userFieldId: ``,
      ifElse: ``,
    }
  },
  {
    name: RegistrationField.PHONOGRAMS,
    setting: {
      componentId: ``,
      title: `Фонограммы`,
      isRequired: false,
      textBlocks: PHONOGRAMS_TEXT_BLOCKS,
      userFieldId: ``,
      ifElse: ``,
    }
  },
  {
    name: RegistrationField.START_POINT,
    setting: {
      title: `Начало выступления`,
      isRequired: false,
      placeholder: ``,
      ifElse: ``,
    }
  },
  {
    name: RegistrationField.DURATION,
    setting: {
      title: `Длительность`,
      isRequired: false,
      ifElse: ``,
    }
  },
  {
    name: RegistrationField.NOTE,
    setting: {
      componentId: ``,
      title: `Комментарий`,
      isRequired: false,
      placeholder: ``,
      optionItems: [],
      templateRequest: ``,
      ifElse: ``,
    }
  },
];

export const CONTEST_FORM_FIELD = {
  name: RegistrationField.CONTEST,
  setting: {
    isShowAddress: true,
    isShowDateContest: true,
    isShowPattern: false,
    // isShowPattern: true,
    isShowOrganizationLink: true,
    buttonTitle: `Зарегистрироваться`,
    pattern:
    `Для регистрации на мероприятие [[contestTitle]], 
которое состоится [[contestDate]] по адресу [[contestAddress]], 
заполните анкету. 

Регистрация до: [[affirmationDeadline]]. 

Оплата до: [[paymentDeadline]].`,
//     popupRegistration: {
//       name: `Всплывающее окно «Заявка получена» <br>Переменные <b>[[performance_id]]</b> - номер заявки(-ок), <b>[[price]]</b> - стоимость, <b>[[currency]]</b> - валюта`,
//       title: `Заявка получена`,
//       isPattern: true,
//       text: `Ваша заявка получена, ей присвоен номер [[performance_id]].<br>
// Обработка заявки начнется только ПОСЛЕ оплаты регистрационного взноса в размере [[price]] [[currency]] за заявку.`,
//     },
//     popupRegistrationWithoutPay: {
//       name: `Всплывающее окно «Заявка получена» без оплаты <br>Переменные <b>[[performance_id]]</b> - номер заявки(-ок)`,
//       title: `Номер зарегистрирован`,
//       isPattern: true,
//       text: `Ваша заявка зарегистрирована, ей присвоен номер [[performance_id]].`
//     }
  },
};

// contest_service_price

export const AUTHORIZATION_FORM_FIELD = {
  name: RegistrationField.AUTHORIZATION,
  setting: {
    isShowMiddlename: true,
    isMiddlenameRequired: true,
    popup: {
      name: `Всплывающее окно после авторизации`,
      title: `Приятно познакомиться!`,
      text: `Организатор получил Ваши данные.

Вам создан личный кабинет. Логин и пароль для входа отправлены на указанный адрес электронной почты.
      
<i>А теперь  расскажите нам самое главное – детали Вашего выступления! </i>`,
    },
    popupConvention: {
      name: `Всплывающее окно «Согласие на обработку персональных данных»`,
      title: ``,
      isPattern: true,
      text: `Нажимая кнопку «Далее», я:<br>
      – принимаю условия <a href="[[contest_rules_link]]" target="_blank">Положения мероприятия</a> «[[contest_title]]»,<br>
      – при этом я даю <a href="[[privacy_policy_ptt]]" target="_blank">Согласие на обработку персональных данных</a> организатору мероприятия,`,
    },
    textBlocks: AUTH_TEXT_BLOCKS,
  }
};

export const REGISTRATION_POPUP_FIELD = {
  name: RegistrationField.REGISTRATION_POPUP,
  setting: {
    title: `Настройка всплывающих окон после регистрации`,
    popupRegistrationWithPay: {
      name: `Всплывающее окно «Заявка получена», если включена оплата <br>Переменные <b>[[performance_id]]</b> - номер заявки(-ок), <b>[[price]]</b> - стоимость, <b>[[currency]]</b> - валюта`,
      title: `Заявка получена`,
      isPattern: true,
      text: `Ваша заявка получена, ей присвоен номер [[performance_id]].<br>
Обработка заявки начнется только ПОСЛЕ оплаты регистрационного взноса в размере [[price]] [[currency]] за заявку.`,
    },
    popupRegistrationWithoutPay: {
      name: `Всплывающее окно «Заявка получена» без оплаты <br>Переменные <b>[[performance_id]]</b> - номер заявки(-ок)`,
      title: `Номер зарегистрирован`,
      isPattern: true,
      text: `Ваша заявка зарегистрирована, ей присвоен номер [[performance_id]].`
    }
  },
};

export const PAYMENT_FORM_FIELD = {
  name: RegistrationField.PAYMENT,
  setting: {
    title: `Оплата`,
  }
};

export const COMMON_FIELD = {
  name: RegistrationField.COMMON,
  setting: {
    title: `Настройка кнопок`,
    blocks: [
      {
        name: `button_ok`,
        note: `Кнопка ОК`,
        text: `ОК`
      },
      {
        name: `button_cancel`,
        note: `Кнопка Отмена`,
        text: `Отмена`
      },
      {
        name: `button_back`,
        note: `Кнопка Назад`,
        text: `Назад`
      },
      {
        name: `button_continue`,
        note: `Кнопка Далее`,
        text: `Далее`
      },
      {
        name: `button_clear`,
        note: `Кнопка Очистить`,
        text: `Очистить`
      },
      {
        name: `button_registration`,
        note: `Кнопка Отправить заявку`,
        text: `Отправить заявку`
      }
    ]
  },
};


export const LinkTarget = {
  BLANK: `_blank`,
  SELF: `_self`,
};

export const LinkTargetEnToLinkTargetRus = {
  [LinkTarget.BLANK]: `В новом окне`,
  [LinkTarget.SELF]: `В текущем окне`,
};

export const LINK_TARGETS = [
  {
    id: LinkTarget.BLANK,
    text: LinkTargetEnToLinkTargetRus[LinkTarget.BLANK],
  }, {
    id: LinkTarget.SELF,
    text: LinkTargetEnToLinkTargetRus[LinkTarget.SELF],
  },
];


export const SpecialPrizeMode = {
  CHECK: `check`,
  WRITE: `write`,
  CHECK_WRITE: `check-write`,
};

export const SpecialPrizeModeToRus = {
  [SpecialPrizeMode.CHECK]: `Выбирать из выпадающего списка`,
  [SpecialPrizeMode.WRITE]: `Писать вручную`,
  [SpecialPrizeMode.CHECK_WRITE]: `Оба варианта`,
};

export const ViewGroupMode = {
  EXPAND: `expand`,
  COLLAPSE: `collapse`,
};

export const ViewGroupModeToText = {
  [ViewGroupMode.EXPAND]: `Заявки видны`,
  [ViewGroupMode.COLLAPSE]: `Заявки скрыты`,
};


export const TabModeRole = {
  NONE: `none`,
  ORGANIZATOR: [UserRole.OWNER, UserRole.ADMIN].join(`,`),
  ALL: `all`,
};

export const TabModeRoleToRus = {
  [TabModeRole.ALL]: `Показывать всем`,
  [TabModeRole.NONE]: `Не показывать никому`,
  [TabModeRole.ORGANIZATOR]: `Показывать организаторам`,
};

export const TabModeRoleToColor = {
  [TabModeRole.ALL]: `green`,
  [TabModeRole.NONE]: `#ec0018`,
  [TabModeRole.ORGANIZATOR]: `#FDDA0D`,
};


export const GroupBy = {
  PEOPLE_DIRECTOR: `people-director`,
  PEOPLE_PLAYER: `people-player`,
  PLAYER: `player`,
  PEOPLE_REFEREE: `people-referee`,
  PERFORMANCE: `performance`,
  GROUP: `group`,
  SINGLE_PAGE: `single-page`,
};


export const CountryCompany = {
  RUSSIA: `ru`,
  OTHER: `other`,
};

// 1657
