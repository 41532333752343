import {KEY_NAME} from '../../const.js';
import Popup, {ContentType, PopupSize} from '../../static/popup/popup.js';
import {getChangedQuotText} from '../../utils/common.js';
import {render, remove, RenderPosition} from '../../utils/render.js';
import AbstractSmartComponent from '../abstract-smart-component.js';
import DropDownComponent from '../input/drop-down/drop-down.js';
import ErrorMessageComponent from '../input/error-message/error-message.js';

export default class AbstractInputComponent extends AbstractSmartComponent {
  constructor({
    api = null,
    searchContestAPI = null,
    name = ``,
    label = ``,
    placeholder = ``,
    extraClassName = ``,
    style = ``,
    value = ``,
    values = [],
    minValue = 0,
    maxValue = ``,
    inputmode = ``,
    step = 1,
    maxLength = ``,
    rows = `2`,
    resize = `none`,
    buttonTitle = ``,
    buttonId = ``,
    isRequired = false,
    isDisabled = false,
    prompt = ``,
    mode = `passive`,
    isDeleteButton = false,
    isTrashButton = false,
    isReadOnly = false,
    dropDownItems = [],
    onDropDownItemCheck = null,
    onDropDownItemCheck2 = null,
    onDisabledDropDownItemCheck = null,
    isPopupDropDown = false,
    isDropDown = null,
    isDisableEmptyDropDown = true,
    inputId = ``,
    type = ``,
    isChangeValueText = true,
    isMultiChoiceDropDown = false,
    buttonInDropDownTitle = ``,
    onButtonInDropDownClick = null,
    customValidate = () => true,
    errorValidateText = ``,
    searchExeption = [],
    isPositions = false,
    isShowUpDownArrow = true,
    isShow = true,
  }) {
    super();

    this._api = api;
    this._searchContestAPI = searchContestAPI;
    this._name = name;
    this._label = label;
    this._value = value;
    this._values = values;
    this._placeholder = placeholder;
    this._extraClassName = extraClassName;
    this._style = style;
    this._minValue = minValue;
    this._maxValue = maxValue;
    this._inputmode = inputmode;
    this._step = step;
    this._maxLength = maxLength;
    this._rows = rows;
    this._resize = resize;
    this._buttonTitle = buttonTitle;
    this._buttonId = buttonId;
    this._isRequired = isRequired;
    this._prompt = prompt;
    this._isValid = null;
    this._mode = mode;
    this._inputId = inputId;
    this._type = type;

    this._isDeleteButton = isDeleteButton;
    this._isTrashButton = isTrashButton; // рисуется рядом с инпутом, а не внутри
    this._isReadOnly = isReadOnly;
    this._dropDownItems = dropDownItems;
    this._dropDownComponent = null;
    this._onDropDownItemCheck = onDropDownItemCheck;
    this._onDropDownItemCheck2 = onDropDownItemCheck2;
    this._onDisabledDropDownItemCheck = onDisabledDropDownItemCheck;
    this._isDisableEmptyDropDown = isDisableEmptyDropDown;
    this._isDisabled = (this._isReadOnly && this._dropDownItems.length === 0 && this._isDisableEmptyDropDown) ? true : isDisabled;
    this._isOpenDropDownItems = false;
    this._isPopupDropDown = isPopupDropDown;
    this._isDropDown = isDropDown;
    // this._popupDropDown = null;
    this._popupDropDownController = null;
    this._isMultiChoiceDropDown = isMultiChoiceDropDown;
    this._buttonInDropDownTitle = buttonInDropDownTitle;
    this._onButtonInDropDownClick = onButtonInDropDownClick;
    this._customValidate = customValidate;
    this._errorValidateText = errorValidateText;
    this._searchExeption = searchExeption;

    this._isChangeValueText = isChangeValueText;

    this._isPositions = isPositions;

    this._isShowUpDownArrow = isShowUpDownArrow;

    this._isShow = isShow;

    this._textPopup = ``;

    this._isRemove = false;

    this._messageComponent = null;

    this._changeHandler = null;
    this._inputHandler = null;
    this._inputPopupHandler = null;
    this._upButtonClickHandler = null;
    this._downButtonClickHandler = null;
    this._deleteButtonClickHandler = null;
    this._trashButtonClickHandler = null;
    this._enterClickHandler = null;
    this._focusHandler = null;
    this._onOptionClickHandler = this._onOptionClickHandler.bind(this);
    this._onCheckDropDownDocumentClick = this._onCheckDropDownDocumentClick.bind(this);
    this._onRemovePopupDropDownHandler = this._onRemovePopupDropDownHandler.bind(this);
    this._onKeyPress = this._onKeyPress.bind(this);

    this._init();
    this._validate();

    this._setChangeHandler();
    this._setInputHandler();
    this._onDeleteButtonClickHandler();
    this._onTrashButtonClickHandler();
    this._setArrowButtonClickHandler();
    this._setShowButtonClickHandler();
    this._setMouseOverHandler();
    this._setMouseOutHandler();
    this._renderDropDown();
  }

  recoveryListeners() {
    this._setChangeHandler();
    this._setInputHandler();
    this._onDeleteButtonClickHandler();
    this._onTrashButtonClickHandler();
    this._setArrowButtonClickHandler();
    this._setShowButtonClickHandler();
    this._setMouseOverHandler();
    this._setMouseOutHandler();
    this._renderDropDown();

    this.setDeleteButtonClickHandler(this._deleteButtonClickHandler);
    this.setTrashButtonClickHandler(this._trashButtonClickHandler);
    this.setChangeHandler(this._changeHandler);
    this.setInputHandler(this._inputHandler);
    this.setInputPopupHandler(this._inputPopupHandler);
    this.setArrowUpButtonClickHandler(this._upButtonClickHandler);
    this.setArrowDownButtonClickHandler(this._downButtonClickHandler);
    this.setEnterClickHandler(this._enterClickHandler);
    this.setFocusHandler(this._focusHandler);
  }

  _renderDropDown() {
    if (!this.isDropDown) {
      return;
    }

    this._dropDownComponent = new DropDownComponent(this._dropDownItems, this._buttonInDropDownTitle);
    render(this.getElement(), this._dropDownComponent.getElement(), RenderPosition.BEFOREEND);
    this._dropDownComponent.setOptionClickHandler(this._onOptionClickHandler);
    this._dropDownComponent.setButtonClickHandler(this._onButtonInDropDownClick);
  }

  get inputElement() {
    return this.getElement().querySelector(`.dropDownList__input`);
  }

  get value() {
    if (!this.inputElement) {
      return ``;
    }

    const value = this.inputElement.value;
    this.value = value;
    return value;
  }

  get name() {
    return this._name;
  }

  get type() {
    return this._type;
  }

  get label() {
    return this._label;
  }

  get isValid() {
    if (!this._isRequired) {
      return true;
    }

    return this._isValid;
  }

  get errorValidateText() {
    return this._errorValidateText;
  }

  getIsValid(mode) {
    this._mode = mode;
    if (mode === `active`) {
      this._validate();
    }

    return (this._isValid) ? this._isValid : false;
  }

  validate() {
    this._validate();
  }

  get validity() {
    return {
      isValid: this.isValid,
      label: this.label,
    };
  }

  get isRemove() {
    return this._isRemove;
  }

  get isDeleteButton() {
    return this._isDeleteButton;
  }

  get inputId() {
    return this._inputId;
  }

  get dropDownItems() {
    return this._dropDownItems;
  }

  get isDropDown() {
    if (this._isDropDown === null) {
      return this._dropDownItems.length > 0;
    }

    return this._isDropDown;
  }

  get isOpenDropDownItems() {
    return this._isOpenDropDownItems;
  }

  get isShow() {
    return this._isShow;
  }

  set inputId(data) {
    this._inputId = data;
  }

  set value(data) {
    this._value = data;
    if (this.inputElement) {
      this.inputElement.value = data;
    }
  }

  set values(datum) {
    this._values = datum;
  }

  set isRemove(data) {
    this._isRemove = data;
  }

  set isDeleteButton(data) {
    this._isDeleteButton = data;
    super.rerender();
  }

  set isValid(isValid) {
    this._isValid = isValid;
  }

  set isDisabled(data) {
    /* при rerender долго перерисовывается элемент,
    поэтому быстрее если поставить data-атрибут */
    this._isDisabled = data;
    this.getElement().dataset.disabled = data;
  }

  set textPopup(data) {
    this._textPopup = data;
  }

  set isSearching(data) {
    this._isSearching = data;

    this.getElement().dataset.searching = this._isSearching;
  }

  set isShow(data) {
    this._isShow = data;
  }

  showValidate() {
    if (this._mode === `active`) {
      if (!this._isRequired) {
        this.getElement().dataset.validate = `ok`;
      } else {
        this.getElement().dataset.validate = (this._isValid) ? `ok` : `error`;
      }
    }
  }

  set mode(value) {
    this._mode = value;
    this.showValidate();
  }

  set dropDownItems(data) {
    this._dropDownItems = data;
    if (data.length > 0 && this._isDisableEmptyDropDown) {
      this.isDisabled = false;
    }
    if (this._dropDownComponent) {
      this._dropDownComponent.dropDownItems = this._dropDownItems;
    } else {
      this.getElement().dataset.btnStatus = `show`;
      this._renderDropDown();

      // super.rerender();
    }
  }

  set onDropDownItemCheck(handler) {
    if (!this.isDropDown) {
      return;
    }
    this._onDropDownItemCheck = handler;
  }

  set isOpenDropDownItems(data) {
    this._isOpenDropDownItems = data;
    if (this._isOpenDropDownItems) {
      document.addEventListener(`click`, this._onCheckDropDownDocumentClick);
    } else {
      document.removeEventListener(`click`, this._onCheckDropDownDocumentClick);
    }
    this.getElement().dataset.list = this._isOpenDropDownItems ? `show` : `hide`;
  }

  set searchExeption(data) {
    this._searchExeption = data;
  }

  _validate() {}

  _addValue(data) {
    if (this._values.includes(data)) {
      return;
    }

    this._values.push(data);
    this.value = this._values.join(`, `);
  }

  _hideDropDownItems() {
    this._isOpenDropDownItems = false;
    if (this._isPopupDropDown) {
      this._removePopupDropDown();

      return;
    }

    document.removeEventListener(`click`, this._onCheckDropDownDocumentClick);
    this.getElement().dataset.list = `hide`;
  }

  _showDropDownItems() {
    this._isOpenDropDownItems = true;
    if (this._isPopupDropDown) {
      this._renderPopupDropDown();
      return;
    }

    document.addEventListener(`click`, this._onCheckDropDownDocumentClick);
    this.getElement().dataset.list = `show`;
  }

  _renderPopupDropDown() {
    const inputId = this.inputId;

    if (inputId !== ``) {
      this._dropDownItems = this._dropDownItems.map((item) => {
        item.isChecked = item.id === inputId;
        return item;
      });
    }

    this._dropDownComponent = new DropDownComponent(this._dropDownItems);
    this._dropDownComponent.getElement().style.position = `relative`;
    this._dropDownComponent.setOptionClickHandler(this._onOptionClickHandler);

    const contents = [];

    if (this._textPopup) {
      contents.push({
        type: ContentType.TEXT,
        content: this._textPopup,
      });

      this._textPopup = ``;
    }

    contents.push({
      type: ContentType.FRAGMENT,
      content: this._dropDownComponent.getElement(),
    });

    Popup.showCustom({
      size: PopupSize.SMALL,
      title: `Выберите из списка`,
      onClosePopupHandler: this._onRemovePopupDropDownHandler,
      contents,
    })
    .then((popupController) => {
      this._popupDropDownController = popupController;
    });
  }

  _removePopupDropDown() {
    this._popupDropDownController.close();
    this._popupDropDownController = null;
  }

  _onRemovePopupDropDownHandler() {
    this._isOpenDropDownItems = false;
    this._popupDropDownController = null;
  }

  _onKeyPress() {}

  _toggleDropDownItems(isShow) {
    if (isShow) {
      this._showDropDownItems();
      return;
    }

    this._hideDropDownItems();
  }

  focusInput() {
    const input = this.inputElement;
    input.focus();
    if (input && input.selectionStart !== null) {
      input.selectionStart = input.value.length;
    }
  }

  selectInput() {
    const input = this.inputElement;
    input.select();
  }

  openDropDown() {
    this._showDropDownItems();
    super.rerender();
  }

  setValue(data) {
    this.value = data;
    this._validate();
  }

  showError(text) {
    this._renderDetailError(text);
  }

  hideError() {
    this._removeDetailError();
  }

  clear() {
    this._clear();
  }

  _init() {
    if (!this.inputElement) {
      return;
    }

    this.inputElement.addEventListener(`focus`, () => {
      if (this._isPopupDropDown && !this._isReadOnly) {
        return;
      }
      this._showDropDownItems();
    });
  }

  _clear() {
    this.value = ``;
    this.inputId = ``;
    super.rerender();
  }

  _renderDetailError(text) {
    if (this._messageComponent) {
      this._messageComponent.text = text;
      return;
    }

    if (text) {
      this._messageComponent = new ErrorMessageComponent(text);
      render(this.getElement(), this._messageComponent.getElement(), RenderPosition.BEFOREEND);
    }

    this.getElement().dataset.error = `true`;
  }

  _removeDetailError() {
    if (this._messageComponent) {
      remove(this._messageComponent);
      this._messageComponent = null;
    }
    this.getElement().dataset.error = `false`;
  }

  _checkDeleteButton() {
    if (this.value === `` && this.isDeleteButton) {
      this.isDeleteButton = false;
      return;
    }

    if (!this.isDeleteButton) {
      this.isDeleteButton = true;
    }
  }

  _onCheckDropDownDocumentClick(evt) {
    const target = evt.target;
    const isContains = this.getElement().contains(target);

    if (isContains) {
      return;
    }

    this._toggleDropDownItems(isContains);
  }

  _onOptionClickHandler(id, isDisabled) {
    this._toggleDropDownItems(!this.isOpenDropDownItems);
    if (isDisabled) {
      if (this._onDisabledDropDownItemCheck) {
        this._onDisabledDropDownItemCheck(id);
      }

      return;
    }

    // if (this.inputId === id) {
    //   return;
    // }

    if (id === `addButton`) {
      this.inputId = ``;
    } else {
      this.inputId = id;

      if (this._isChangeValueText) {
        const option = this._dropDownItems.find((item) => String(item.id) === String(id));
        const value = option.source ? option.source : option.text;

        if (this._isMultiChoiceDropDown) {
          this._addValue(value);
        } else {
          this.value = value;
        }
      }
    }

    if (this._isRequired) {
      this._validate();
    }

    if (this._onDropDownItemCheck) {
      this._onDropDownItemCheck(id, this.getElement(), this);
    }

    if (this._onDropDownItemCheck2) {
      this._onDropDownItemCheck2({value: this.value, inputId: id, name: this.name});
    }

    if (this.name === `buttonInput`) {
      // если тип кнопка с инпутом то на кнопке сохранять выбранный вариант
      this._buttonId = id;

      const option = this._dropDownItems.find((item) => item.id === id);
      this._buttonTitle = option.text;

      this.getElement().querySelector(`.dropDownList__titleBtn`).textContent = this._buttonTitle;
    }
  }

  _onChangeHandler() {}

  _onInputHandler() {}

  _setChangeHandler() {
    if (!this.inputElement) {
      return;
    }

    this.inputElement.addEventListener(`change`, () => {
      if (this._isMultiChoiceDropDown) {
        this.values = this.inputElement.value.split(`, `);
      }
      this._onChangeHandler();
    });
  }

  _setInputHandler() {
    if (!this.inputElement) {
      return;
    }

    this.inputElement.addEventListener(`input`, () => {
      this._onInputHandler();
    });
  }

  _setMouseOverHandler() {
    if (this._prompt === `` || !this.getElement().querySelector(`.dropDownList__btnPrompt`)) {
      return;
    }

    this.getElement().querySelector(`.dropDownList__btnPrompt`)
    .addEventListener(`mouseover`, () => {
      this.getElement().dataset.promptText = `open`;
    });
  }

  _setMouseOutHandler() {
    if (this._prompt === `` || !this.getElement().querySelector(`.dropDownList__btnPrompt`)) {
      return;
    }

    this.getElement().querySelector(`.dropDownList__btnPrompt`)
    .addEventListener(`mouseout`, () => {
      this.getElement().dataset.promptText = `close`;
    });
  }

  setArrowUpButtonClickHandler() {}

  setArrowDownButtonClickHandler() {}

  _setArrowButtonClickHandler() {
    // if (!this.isDropDown) {
    //   return;
    // }
    const oneArrowButton = this.getElement().querySelector(`.dropDownList__btnOpen--oneArrow`);

    if (!oneArrowButton) {
      return;
    }

    oneArrowButton.addEventListener(`click`, () => this._toggleDropDownItems(!this.isOpenDropDownItems));
  }

  _setShowButtonClickHandler() {
    if (!this.isDropDown) {
      return;
    }

    const iconButton = this.getElement().querySelector(`.dropDownList__btnIcon`);

    if (!iconButton) {
      return;
    }

    iconButton.addEventListener(`click`, () => {
      this._toggleDropDownItems(!this.isOpenDropDownItems);
    });
  }

  _searchDropDownItems() { // поиск в уже готовом выпадающем списке
    if (!this.isDropDown) {
      return;
    }
    const entered = this.value.toLowerCase();
    const foundItems = this.dropDownItems.filter((item) => item.text.toLowerCase().indexOf(entered) >= 0);

    this._dropDownComponent.dropDownItems = foundItems;
    if (!this._isPositions) {
      this._showDropDownItems();
    }
  }

  _onDeleteButtonClickHandler() {
    if (!this._isDeleteButton) {
      return;
    }

    if (!this.getElement().querySelector(`.master__btn--delete`)) {
      return;
    }

    this.getElement().querySelector(`.master__btn--delete`)
      .addEventListener(`click`, () => this._clear());
  }

  setDeleteButtonClickHandler(handler) {
    if (!handler) {
      return;
    }

    this._deleteButtonClickHandler = handler;

    if (!this._isDeleteButton) {
      return;
    }

    this.getElement().querySelector(`.master__btn--delete`)
      .addEventListener(`click`, () => handler(this));
  }

  _onTrashButtonClickHandler() {
    if (!this._isTrashButton) {
      return;
    }

    this.getElement().querySelector(`.master__btn--deleteSvg`)
      .addEventListener(`click`, () => this._clear());
  }

  setTrashButtonClickHandler(handler) {
    if (!handler) {
      return;
    }

    this._trashButtonClickHandler = handler;

    if (!this._isTrashButton) {
      return;
    }

    this.getElement().querySelector(`.master__btn--deleteSvg`)
      .addEventListener(`click`, () => handler(this));
  }

  setEnterClickHandler(handler) {
    if (!handler) {
      return;
    }

    if (!this.inputElement) {
      return;
    }

    this.inputElement.addEventListener(`keydown`, (evt) => {
      // this._validate();
      // handler(getChangedQuotText(this.value), this.inputId);
      if (evt.key === KEY_NAME.ENTER) {
        handler(this.value);
      }
    });

    this._enterClickHandler = handler;
  }

  setFocusHandler(handler) {
    this._focusHandler = handler;

    if (!handler) {
      return;
    }

    if (!this.inputElement) {
      return;
    }

    this.inputElement.addEventListener(`focus`, handler);
  }

  setInputHandler(handler) {
    if (!handler) {
      return;
    }

    if (!this.inputElement) {
      return;
    }

    this.inputElement.addEventListener(`input`, () => {
      this._validate();
      handler(getChangedQuotText(this.value), this.inputId, this.name);
    });

    this._inputHandler = handler;
  }

  setInputPopupHandler(handler) {
    if (!handler) {
      return;
    }

    if (!this.inputElement) {
      return;
    }

    this.inputElement.addEventListener(`input`, () => {
      this._validate();
      handler({
        name: this.name,
        value: getChangedQuotText(this.value),
        inputId: this.inputId,
      });
    });

    this._inputPopupHandler = handler;
  }

  setChangeHandler(handler) {
    if (!handler) {
      return;
    }

    if (!this.inputElement) {
      return;
    }

    this.inputElement.addEventListener(`change`, () => {
      this._validate();
      handler(getChangedQuotText(this.value), this.inputId);
    });

    this._changeHandler = handler;
  }
}
